<template>
  <div class="relative">
    <button
      type="button"
      class="w-full focus:outline-none"
      tabindex="-1"
      @click.prevent="toggleList(!listOpen)"
    >
      <el-input
        :id="id"
        ref="searchInput"
        v-model="searchValue"
        v-loading="!listOpen && loading"
        class="th-input"
        :class="{ disabled }"
        :clearable="clearable"
        :placeholder="
          placeholder ||
          $t('components.remote_search_select.placeholder_default')
        "
        :disabled="disabled"
        @input="handleInput"
        @focus="handleInputFocus"
        @blur="handleInputBlur"
        @clear="clear"
      />
    </button>

    <div
      v-if="messageText"
      class="text-xs leading-none mt-1 absolute left-0 text-th-info-yellow"
    >
      {{ messageText }}
    </div>

    <transition :name="transition">
      <div
        v-show="listOpen"
        ref="list"
        class="el-select-dropdown el-popper absolute border rounded-th-normal bg-white my-3"
        :x-placement="popper.placement"
        :style="{ width: `${width}px`, zIndex: 3000 }"
      >
        <div
          v-if="localList.length"
          class="overflow-hidden"
          :class="[localList.length > 8 ? 'th-list-height' : 'h-full']"
        >
          <virtual-list
            ref="dropdown"
            :model-value="modelValue"
            :loading="loading"
            :label-field="labelField || optionsValue"
            :key-field="optionsValue"
            :list="localList"
            @select="pickItem($event)"
            @paginate="!searchTerm && $emit('paginate')"
          />
        </div>
        <div
          v-else
          class="h-full flex justify-center items-center leading-8 text-th-primary-gray"
          style="min-height: 4rem"
        >
          <div v-if="error">{{ _errorText }}</div>
          <div v-else-if="loading">{{ _loadingText }}</div>
          <div v-else>{{ _noDataText }}</div>
        </div>
        <div class="popper__arrow" style="left: 35px" />
      </div>
    </transition>
  </div>
</template>

<script>
import debounce from 'debounce'
import popperSetup from '../popper'
import VirtualList from '../remote-search/virtual-list'

export default {
  name: 'Search',

  components: {
    VirtualList
  },

  props: {
    modelValue: {
      type: String,
      default: undefined
    },
    id: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    loadingText: {
      type: String,
      default: undefined
    },
    noDataText: {
      type: String,
      default: undefined
    },
    clearable: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    errorText: {
      type: String,
      default: undefined
    },
    loading: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default: () => []
    },
    labelField: {
      type: String,
      default: 'name'
    },
    /**
     * The key used to get the value for the options-children of the dropdown.
     * Defaults to "id"
     */
    optionsValue: {
      type: String,
      default: 'id'
    },
    // Message to be shown under the input
    messageText: {
      type: String,
      default: ''
    }
  },
  emits: ['update:modelValue', 'resource-set', 'paginate'],

  data() {
    return {
      width: 260,
      listOpen: false,
      error: false,
      searchTerm: '',
      popper: {}
    }
  },

  computed: {
    searchValue: {
      get() {
        return this.searchTerm || this.displayValue
      },
      set(value) {
        this.searchTerm = value
      }
    },
    localList() {
      return this.list.filter((item) =>
        (item.computed_name || item[this.labelField] || item[this.optionsValue])
          .toLowerCase()
          .includes(this.searchTerm.toLowerCase())
      )
    },
    displayValue() {
      if (!this.modelValue) return ''
      const item = this.list.find(
        (item) => (item[this.optionsValue] || item.id) === this.modelValue
      )
      return item
        ? item.computed_name || item[this.labelField] || item[this.optionsValue]
        : ''
    },
    _noDataText() {
      return this.noDataText || this.$t('common.data.no_data')
    },
    _loadingText() {
      return this.loadingText || this.$t('common.interactions.loading.default')
    },
    transition() {
      const popperPlacementY = this.popper?.placement?.split('-')[0] ?? 'bottom'
      return {
        top: 'el-zoom-in-bottom',
        bottom: 'el-zoom-in-top'
      }[popperPlacementY]
    }
  },

  watch: {
    modelValue() {
      this.handleWatch()
    },
    list() {
      this.handleWatch()
    }
  },

  mounted() {
    window.addEventListener('resize', this.getWidth)
    this.popper = popperSetup.setup(
      this.$refs.searchInput.$el,
      this.$refs.list,
      {
        placement: 'bottom-start'
      }
    )
    this.getWidth()
    this.popper.create()
  },

  beforeUnmount() {
    // clear the passed list so it won't persist for next mount.
    window.removeEventListener('resize', this.getWidth)
    this.popper.destroy()
  },

  methods: {
    handleWatch() {
      const item = this.list.find(
        (item) => item[this.optionsValue] === this.modelValue
      )
      // FIXME: Resource set is emitted twice
      if (item) this.$emit('resource-set', item)
    },
    getWidth() {
      const inputWidth = this.$refs.searchInput?.$el.getBoundingClientRect()
        .width
      if (inputWidth > 0) {
        this.width = inputWidth
      }
    },
    toggleList: debounce(async function (openState) {
      if (this.disabled) return
      await this.$nextTick()
      this.listOpen = openState && !!this.localList?.length
      await this.$nextTick()
      this.popper.computePlacement()
    }, 150),
    handleInput(value) {
      if (!value) {
        this.searchTerm = ''
        this.$emit('update:modelValue', undefined)
        this.toggleList(true)
        return
      }
      this.searchTerm = value
    },
    handleInputFocus() {
      this.toggleList(true)
    },
    handleInputBlur() {
      setTimeout(() => {
        this.toggleList(false)
      }, 150)
    },
    pickItem(item) {
      this.searchTerm = ''
      this.toggleList(false)

      if (!item) {
        this.$emit('update:modelValue', undefined)
        this.$emit('resource-set', undefined)
        return
      }

      this.$emit('update:modelValue', item[this.optionsValue])
      this.$emit('resource-set', item)
    },
    clear() {
      this.pickItem()
    }
  }
}
</script>

<style scoped>
.th-input :deep(.el-input__inner) {
  cursor: pointer;
}

.th-input.disabled :deep(.el-input__inner) {
  cursor: not-allowed;
}

.th-input :deep(.el-loading-mask) {
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;
  border-radius: 3px;
}

.th-input :deep(.el-loading-spinner) {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  top: 0;
  margin-top: 0;
}

.th-input :deep(.el-loading-spinner svg) {
  height: 25px;
  width: 25px;
}

.th-list-height {
  height: 20rem;
}
</style>
